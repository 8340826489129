<template>
  <div class="view pa24">
    <div class="d-flex">
      <div style="display: flex">
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="请输入文章名称"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" style="height: 34px!important;margin-left: 20px" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 新增文档
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="title" align="center" label="文章标题"/>
        <el-table-column prop="uploadTime" align="center" label="置顶" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.ifTop"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑
            </el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.kfqArticleId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增文档"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24 project_wrap"
          label-position="left"
      >
        <el-form-item prop="title" label="文档标题">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入文档标题"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="文档内容" prop="coverFile">
          <div class="flex-div uploaditem">
            <el-tooltip class="item" effect="dark" :content="tag.name" placement="top-start"
                        v-for="(tag,index) in fileList" :key="index">
              <el-tag style="" :disable-transitions="false" @close="handleClose(index)" closable
                      @click="downloadFile(tag)"><i class="el-icon-paperclip"></i><span
                  class="tagtext">{{ tag.name }}</span></el-tag>
            </el-tooltip>
            <el-upload
                v-if="fileList.length==0"
                class="upload-demo"
                :action="$store.state.uploadingUrl"
                ref="upload"
                :limit="1"
                :on-remove="handleRemoveFile"
                :on-success="handleSuccessFile"
                :file-list="fileList"
                :before-upload="beforeUpload"
                :show-file-list="false"
            >
              <el-button class="btn" style="height: 40px"><i class="el-icon-paperclip"></i>点击上传PDF文档</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="显示状态" prop="isDistribution">
          <el-radio-group v-model="ruleForm.status">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";

import {addKfqArticle,selectKfqArticlePageList,upKfqArticle,delKfqArticle,upKfqArticleTop} from "@/api/serviceDevelopmentZone";

export default {
  name: "whitePaper",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeProId:'',
      fileType: ["pdf"],
      centerDialogVisible: false,
      ruleForm: {
        title: "",
        coverFile:"",
        status:0,
      },
      fileListImg: [],
      fileList: [],
      dialogVisible: false,
      rules: {
        title: [{required: true, message: "请输入标题", trigger: "blur"}],
        coverFile: [{required: true, message: "请上传文件"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{label: "全部", value: 1}],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate: 1,
      kfqArticleId: "",
      srcList: [],
      ossUrl: this.$store.state.ossUrl,
      typeList: []
    };
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    async queryPage(payload) {
      let data = {
        type:1,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectKfqArticlePageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].ifTop == 0) {
            list[i].ifTop = true
          }else {
            list[i].ifTop = false
          }
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      this.ruleForm.title = data.title;
      // this.ruleForm.details = data.content;
      this.kfqArticleId = data.kfqArticleId
      this.ruleForm.status = data.status
      // this.ruleForm.coverImg = data.coverUrl
      this.ruleForm.coverFile = data.coverUrl
      // this.ruleForm.type = data.typeId
      this.fileList = [{ name: data.coverUrl, uid: 1 }];
      // this.fileListImg = [{ url: data.coverImg, uid: 1 }];
    },
    //删除弹窗
    async del(val) {
      try {
        await delKfqArticle({kfqArticleId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        title: this.filterName,
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      let data = {
        projectName: this.filterName,
        typeId: this.typeProId,
      };
      this.currentPage = val;
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      let data = {
        projectName: this.filterName,
        typeId: this.typeProId,
      };
      this.pageSize = val;
      this.queryPage(data);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        coverFile: "",
        status: 0
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              title: this.ruleForm.title,
              status: this.ruleForm.status,
              coverUrl: this.ruleForm.coverFile,
              type:1,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              data.ifTop = 1
              await addKfqArticle(data);
            } else {
              data.kfqArticleId = this.kfqArticleId
              await upKfqArticle(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleRemove(file, fileList) {
      this.fileListImg = []
      this.ruleForm.coverImg = "";
    },

    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = this.ossUrl + file.response.data
      this.fileListImg = fileList;
    },

    handleRemoveFile(file, fileList) {
      this.fileList = []
      this.ruleForm.coverFile = "";
    },

    handleSuccessFile(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverFile = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$showMessage('上传文件大小不能超过 50MB!');
          return false;
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    handleClose(index) {
      this.fileList.splice(index, 1)
    },
    downloadFile() {

    },
    async saveStatus(row){
      if(row){
        let ajax = {
          kfqArticleId:row.kfqArticleId,
          associationId: localStorage.getItem("associationId"),
        }
        const result = await upKfqArticleTop(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .project_wrap .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .project_wrap .el-upload--text {
  width: 180px;
  height: 50px;
  border: none;
}

.el-icon-paperclip {
  margin-right: 10px;
}
</style>





